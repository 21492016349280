<template>
    <div class="goods-item">
        <!-- picture -->
        <div class="goods-item-img">
            <el-image
                src=""
                fit="scale-down"
                class="goods-item-img-pic">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>
        <!-- num -->
        <div class="goods-item-num">
            <!-- 价格 -->
            <div class="goods-item-num-price text-color">
                ￥350000.00
            </div>
            <!-- 销量 -->
            <div class="goods-item-num-sales">
                售出
                <span class="text-color">{{999}}</span>
                件
            </div>
        </div>
        <!-- title -->
        <router-link :to="routerPath"
            class="goods-item-title"
            tag="div">
            徕卡M系列240型
        </router-link>
    </div>
</template>

<script>
    export default {
        props: {
            routerPath: String
        }
    }
</script>

<style scoped>
.text-color{
    color: var(--color);
}

.goods-item > div + div{
    margin-top: 10px;
}
/* picture */
.goods-item-img{
    position: relative;
}
.goods-item-img::before{
    display: block;
    content: "";
    padding-top: 100%;
}
.goods-item-img-pic{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* num */
.goods-item-num{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.goods-item-num-price{
    flex-shrink: 1;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
}
.goods-item-num-sales{
    flex-shrink: 0;
}
/* title */
.goods-item-title{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.goods-item-title:hover{
    color: var(--color);
}
</style>